@import "~antd/dist/antd.css";
.App {
  text-align: center;
  font-size: 130%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media all and (max-width: 550px) {
  /**Regular Phone**/
  .question-form {
    width: 95%;
    display: block;
    margin-bottom: 0px;
  }
  .title {
    margin-bottom: 0px;
    padding-bottom: 0%;
  }
  .user-guess {
    font-size: 20px;
  }
  .answer {
    font-size: 30px;
    width: 85% !important;
  }
  .question {
    font-size: 32px !important;
    margin-bottom: 7vh;
    line-height: 150%;
  }
  .question-form-button {
    margin-top: 15%;
  }
  .scroll-container {
    margin-top: 3%;
  }
  .score-page {
    align-items: baseline;
  }
  .correct-answer-text {
    margin-top: 3vh !important;
  }
  .four-sigma-background {
    height: 50px !important;
  }
  .line-chart-card {
    width: 42vh !important;
    height: 42vh !important;
  }
}

@media all and (max-height: 750px) {
  /**Small Phone**/
  .your-statistics-cards {
    width: 75px !important;
    height: 75px !important;
    margin: 2% !important;
    padding: 0 !important;
  }
  .your-score-card {
    width: 110px !important;
    height: 110px !important;
  }
  .your-score-number {
    font-size: 30px !important;
    padding: 2px !important;
  }
  .your-score-text {
    font-size: 12px !important;
  }
  .your-statistics-number {
    font-size: 22px !important;
  }
  .your-statistics-text {
    font-size: 9px !important;
  }
  .today-statistics-cards {
    width: 65px !important;
    height: 65px !important;
  }
  .today-statistics-number {
    font-size: 18px !important;
  }
  .ant-space-item {
    margin-bottom: 0 !important;
  }
  .ant-form-item {
    margin: 0 0 14px !important;
  }
  .question {
    margin-bottom: 2vh;
  }
  .scroll-container {
    margin-top: 1% !important;
  }
  .question {
    font-size: 30px !important;
    margin-bottom: 7vh;
    line-height: 150%;
  }
  .further-reading-card {
    width: 300px !important;
    height: 500px !important;
  }
  .correct-answer-text {
    margin-top: 4vh !important;
  }
  .answer {
    font-size: 24px !important;
    width: 80% !important;
  }
  .line-chart {
    width: 20 !important;
    height: 250 !important;
  }
  .line-chart-card {
    width: 50vh !important;
    height: 50vh !important;
  }
}

@media (min-width: 641px) and (max-width: 961px) {
  /* Small Tablet */
  .answer {
    width: 65% !important;
  }
}

@media (min-width: 961px) and (max-width: 1025px) {
  /* Big Tablet */
  .answer {
    width: 65% !important;
  }
}

.answer {
  width: 34%;
}

.question {
  font-size: 50px;
  padding: 4%;
}

.question-form-button {
  margin-top: 4%;
  background-color: #040c17 !important;
}

.question-form-text {
  color: white;
}

.ant-input-number-input {
  text-align: center !important;
}

.page-header {
  border-bottom-style: none !important;
}

.four-sigma-background {
  height: 75px;
}

.score-card {
  width: 100px;
  height: 100px;
  margin: 5%;
  background-color: #040c17 !important;
}

.score-card > .ant-card-body {
  padding: 0 !important;
}

.ant-card {
  margin-bottom: 5% !important;
}

.today-statistics-cards > .ant-card-body {
  padding: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.your-score-card > .ant-card-body {
  padding: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.your-statistics-cards > .ant-card-body {
  padding: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.line-chart-card {
  background-color: #040c17 !important;
  width: 50vh;
  height: 50vh;
}

.your-score-card {
  width: 140px;
  height: 140px;
  background-color: #040c17 !important;
}

.your-score-number {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 20% !important;
  padding: 5px;
}

.your-score-text {
  font-size: 14px;
  font-weight: 500;
  /* margin-top: 10%;
  margin-bottom: 0; */
}

.your-statistics-cards {
  width: 100px;
  height: 100px;
  margin: 5%;
  background-color: #040c17 !important;
}

.correct-answer-card > .ant-card-body {
  padding: 6px !important;
  background-color: #040c17 !important;
  line-height: 0% !important;
  border-radius: 4px !important;
}

.correct-answer-card {
  margin: 1vh !important;
  margin-left: 5vh !important;
  margin-right: 5vh !important;
  margin-bottom: 5vh !important;
}

.correct-answer-text {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2vh;
}

.correct-answer-number {
  font-weight: bold;
  font-size: 18pt;
  margin: 2vh;
}

.your-statistics-number {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 0;
}

.your-statistics-text {
  font-size: 12px;
  font-weight: 400;
}

.today-statistics-cards {
  width: 75px;
  height: 75px;
  background-color: #040c17 !important;
}

.today-statistics-number {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 0;
}

.today-statistics-text {
  font-size: 10px;
  font-weight: 400;
}

.further-reading-card {
  width: 357px;
  height: 561px;
}

.further-reading-links {
  font-size: 20px;
  margin: 3%;
  margin-bottom: 10%;
  line-height: 100%;
}

.further-reading-text {
  padding-bottom: 20%;
}

.further-reading-answer {
  padding-top: 3%;
  padding-bottom: 3%;
}

.ant-btn.ant-btn-primary {
  border-color: black;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 40px;
  padding-left: 50px;
  padding-right: 50px;
}

.red {
  color: red;
}

.green {
  color: green;
}

.login-screen {
  padding-top: 5vh;
  max-width: 40vh;
  min-width: 10vh;
  margin: 0 auto;
}

.summary-button {
  margin: 1vh;
  padding-left: -5vh;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0px;
}

/* All the snapping stuff */
.scroll-container {
  height: 90vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

section {
  height: 90vh;
  scroll-snap-align: center;
}

/* Other styles */
section {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: "relative";
}

section:nth-child(2n) {
  background-color: #1f1f1f;
}

img {
  width: 75%;
}

.four-sigma-background {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.hyperlink {
  color: #00a2ff;
}

.frac {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  letter-spacing: 0.001em;
  text-align: center;
  padding-bottom: 1.3em;
}
.frac > span {
  display: block;
  padding: 0.5em;
  padding-bottom: 0.8em;
}
.frac span.bottom {
  border-top: thin solid white;
}
.frac span.symbol {
  display: none;
}

.statistics {
  padding-top: 2vh;
}
