@import "~antd/dist/antd.css";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px;
  font-family: EB Garamond !important;
  overflow-x: hidden;
}

code {
  font-family: EB Garamond !important;
}
